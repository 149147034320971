import React from 'react';

const Join = React.lazy(() => import('./Pages/Join')); //회원가입
const Login = React.lazy(() => import('./Pages/Login')); //로그인
const Not_Found = React.lazy(() => import('./Pages/Not_Found')); //Not_Found

const route = [
    { path: '/Join', exact: true, name: 'Join', component: Join },
    { path: '/Login', exact: true, name: 'Login', component: Login },
    { path: '/Not_Found', exact: true, name: 'Not_Found', component: Not_Found }
];

export default route;